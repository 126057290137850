<template>
    <el-dialog :visible="visible" width="40%" @close="handleClose" class="dialog" :title="title">
        <el-form ref="form" :model="form" label-width="110px" size="mini" :rules="rules">
            <el-form-item label="小屋名称：" prop="healthHutName">
                <el-input v-model="form.healthHutName" />
            </el-form-item>
            <el-form-item label="小屋简称：" prop="healthHutShortName">
                <el-input v-model="form.healthHutShortName" />
            </el-form-item>
            <el-form-item label="归属地点：" prop="healthHutPlace">
                <el-input v-model="form.healthHutPlace" />
            </el-form-item>
            <el-form-item label="经纬度：" prop="coordinate">
                <el-input v-model="form.coordinate" />
            </el-form-item>
            <el-form-item label="创建日期：" prop="createTime">
                <el-input v-model="form.createTime" />
            </el-form-item>
            <el-form-item label="启用日期：" prop="usedTime">
                <el-input v-model="form.usedTime" />
            </el-form-item>
            <el-form-item label="负责人：" prop="director">
                <el-input v-model="form.director" />
            </el-form-item>
            <el-form-item label="联系方式：" prop="directorPhone">
                <el-input v-model="form.directorPhone" />
            </el-form-item>
            <el-form-item style="padding-top: 40px;text-align: right">
                <el-button type="primary" @click="onSubmit">确 定</el-button>
                <el-button @click="handleClose">取 消</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>

<script>
export default {
    props: {
        visible: {
            default: false
        },
        form: {
            default: {}
        },
        title: {
            default: "添加/修改"
        },
    },
    data() {
        return {
            innerVisible: false,
            roleList: [],
            rules: {
                username: [
                    { required: true, message: '请输入', trigger: 'blur' },
                ],
                nickname: [
                    { required: true, message: '请输入', trigger: 'blur' },
                ],
                password: [
                    { required: true, message: '请输入原密码', trigger: 'blur' },
                    { min: 5, max: 132, message: '长度在 5 到 32 个字符', trigger: 'blur' }
                ],
            }
        }
    },
    methods: {
        async onSubmit() {
            var va = false
            this.$refs.form.validate((valid) => {
                if (valid) {
                    va = true
                }
            })
            if (!va) {
                return
            }
            try {
                let url = `/hut/hut/form`
                const resp = await this.$http.post(url, this.form)
                this.$message[resp.data.status](resp.data.msg)
            } catch (e) {
                this.$message.error(e.message)
            }
            this.$emit('update')
        },
        handleClose() {
            this.$emit('close')
        },
    }
}
</script>

<style lang="scss" scoped>
.dialog ::v-deep .el-dialog__body {
    padding: 10px 20px;
}

.tijian {
    border: 1px solid #c0c4cc;
    padding: 5px;
}

.title {
    display: flex;
    align-items: center;
    padding: 10px;

    .title-text {
        text-align: center;
        border-bottom: 1px solid #EBEEF5;
        border-top: 1px solid #EBEEF5;
        padding: 10px;
    }
}

::v-deep .el-table .el-table__cell {
    padding: 6px 0;
}

.flex {
    display: flex;
}

.btn {
    justify-content: space-between;
    padding: 10px 0;
}

.border-bottom {
    border-bottom: 1px solid #EBEEF5;
}

.text-center {
    text-align: center;
    padding: 5px 0;
}

.align-items {
    align-items: center;
}

.text-group {
    display: grid;
    padding-right: 10px
}
</style>
